@import url('https://fonts.cdnfonts.com/css/ds-digital');
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ra-field > div {
  display: block !important; /* since this is within a grid layout, we override it */
}

.sortableListUpwards {
  animation: upwardsy 0.5s ease-in-out;
}
@keyframes upwardsy {
  0% {
    opacity: 1;
    transform: translateY(0px);
    filter: blur(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(-100px) rotate(5deg);
    filter: blur(10px);
  }
}
.sortableListDownwards {
  animation: downwardsy 0.5s ease-in-out;
}
@keyframes downwardsy {
  0% {
    opacity: 1;
    transform: translateY(0px);
    filter: blur(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(100px) rotate(-5deg);
    filter: blur(10px);
  }
}
.sortableListUpwards button,
.sortableListDownwards button {
  pointer-events: none;
}
